import React, { useState } from "react";

const FilmDevelopmentCalculator = () => {
  const [seconds, setSeconds] = useState(0);
  const [powerResult, setPowerResult] = useState(0);

  const data = [
    { x: 60, y: 100 },
    { x: 65, y: 90 },
    { x: 75, y: 80 },
    { x: 80, y: 70 },
    { x: 90, y: 60 },
    { x: 100, y: 50 },
    { x: 130, y: 40 },
    { x: 165, y: 30 }
  ];

  const calculatePower = () => {
    const secondsValue = parseInt(seconds);

    if (!isNaN(secondsValue)) {
      // Check if secondsValue is a valid number
      // Find the two data points where seconds falls in between
      let lowerPoint, upperPoint;
      for (let i = 0; i < data.length - 1; i++) {
        if (secondsValue >= data[i].x && secondsValue < data[i + 1].x) {
          lowerPoint = data[i];
          upperPoint = data[i + 1];
          break;
        }
      }

      if (lowerPoint && upperPoint) {
        const y =
          lowerPoint.y +
          ((secondsValue - lowerPoint.x) * (upperPoint.y - lowerPoint.y)) /
            (upperPoint.x - lowerPoint.x);
        setPowerResult(y.toFixed(2));
      } else {
        alert("Invalid input or out of range.");
      }
    } else {
      alert("Please enter a valid number of seconds.");
    }
  };

  return (
    <div>
      <br />
      <br />
      <label htmlFor="secondsInput">Enter the number of seconds:</label>
      <input
        type="number"
        id="secondsInput"
        value={seconds}
        onChange={e => setSeconds(e.target.value)}
        required
      />
      <br />
      <br />
      <br />
      <button onClick={calculatePower}>Calculate Power</button>
      <br />
      <br />
      <br />
      <br />
      <br />
      {powerResult !== null && (
        <p>Result: {powerResult}% power is needed for the given time.</p>
      )}
    </div>
  );
};

export default FilmDevelopmentCalculator;
