import React from "react";

import FilmDevelopmentCalculator from "../components/calculator";

import Layout from "../components/layout";
import SEO from "../components/seo";

import "../style/normalize.css";
import "../style/all.scss";

const Calculator = () => {
  return (
    <Layout>
      <SEO title="Calculator" />
      <header className="tag-page-head">
        <h1 className="page-head-title">Film Development Calculator</h1>
        <FilmDevelopmentCalculator />
      </header>
    </Layout>
  );
};

export default () => <Calculator />;
